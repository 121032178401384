<template>
    <EqsCard
        v-bind="{
            color: 'transparent',
            eqsType: 'no-padding',
            loading: !signer.member_sign_page_iframe,
            height: '100%',
            elClass: 'yousign-iframe-container'
        }"
        flat
        class="fill-height fill-width"
    >
        <v-input
            class="fill-width pl-3 custom-info"
            :value="formData.signature_done"
            v-bind="getInputState"
        />
        <v-container class="mt-8 text-center">
            <v-btn
                color="primary"
                x-large
                style="text-transform: none !important"
                @click="openSignaturePage()"
            >
                {{
                    formData.signature_done
                        ? $t("yousign-iframe.btn.show-yousign")
                        : $t("yousign-iframe.btn.go-yousign")
                }}
            </v-btn>
        </v-container>
    </EqsCard>
</template>

<script>
import { formMixin } from "@equisafe-ui-vue/src/mixins/formMixin";

export default {
    name: "GlobalYousignIFrame",
    mixins: [formMixin],
    props: {
        signer: {
            type: Object,
            required: true
        },
        retriable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            signatureBeingApproved: false
        };
    },
    computed: {
        getInputState() {
            let state = {
                successMessages: this.formData.signature_done
                    ? this.$t("yousign-iframe.success.signature-approved")
                    : ""
            };
            state[this.retriable ? "messages" : "error-messages"] = this.signatureBeingApproved
                ? this.$t("yousign-iframe.pending.signature-being-approved")
                : "";
            if (!this.retriable) {
                state.rules = [this.isDocSigned];
            }
            return state;
        }
    },
    watch: {
        signer: {
            immediate: true,
            handler(val, oldVal) {
                if (!!val) {
                    if (!oldVal || val.status !== oldVal.status) {
                        this.checkSignatureStatus(val);
                    }
                }
            }
        }
    },
    mounted() {},
    methods: {
        openSignaturePage() {
            window.open(this.signer.member_sign_page_iframe, "_blank");
        },
        checkSignatureStatus(signer) {
            switch (signer.status) {
                case "finished":
                    this.formData.signature_done = true;
                    this.signatureBeingApproved = false;
                    break;
                case "done":
                    this.formData.signature_done = true;
                    this.signatureBeingApproved = false;
                    break;
                case "active":
                    this.signatureBeingApproved = true;
                    break;
                case "refused":
                    this.$emit("signatureRefused");
                    break;
                default:
                    this.signatureBeingApproved = true;
                    break;
            }
        },
        isDocSigned() {
            return !this.formData.signature_done
                ? this.$t("yousign-iframe.error.required-signature")
                : true;
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-info {
    :deep(.v-messages) {
        font-size: 1.66rem !important;
        margin: 1rem 0 1.5rem 0 !important;
        text-align: center !important;
    }
}
</style>
